<template>
    <div class="head-info">
        <h2>{{userName}}</h2>
        <h3>{{softSkills}}</h3>
    </div>
</template>

<script>
    export default {
        name: "App",
        props: {
            userName: {
                type: String,
                required: true
            },
            softSkills: {
                type: String,
                required: true
            }
        },
        };
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

    h2{
        font-family: "League Spartan", serif;
        font-size: 37px;
        font-weight:400 ;
        color: #EA7B08;
        margin-bottom: 10px;
    }
    h3{
        font-family: "League Spartan", serif;
        font-size: x-large;
        font-weight:300 ;
        color: #298487;
        margin-bottom: 20px;
    }
</style>