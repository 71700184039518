<template>
    <div class="profile">
        <ProfileHeader/>
        <ProfileOverview userImage="" :motivatorImage="motivatorImage" userName="Cecilia de Souza Concalves" softSkills="Comunicador-Executor" />  <!-- Incluir urls da requisicao-->
    </div>
  </template>
  
  <script>
import ProfileHeader from '../components/Profile/ProfileHeader.vue';
import ProfileOverview from '@/components/Profile/ProfileOverview.vue';
import MotivatorImg from '@/assets/icons/profile/motivador1.svg';

export default {
    name: "App",
    components: {
        ProfileHeader,
        ProfileOverview
    },
    data() {
        return {
            motivatorImage: MotivatorImg //mudar para receber insignias dinamicamente
        };
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
/* 
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
} */

.profile{
    padding-inline: 20px;
    height: auto;
    
}

@media screen and (width > 600px){
    .profile{
    padding-inline: 40px;
}
}

@media screen and (width > 900){
    .profile{
    padding-inline: 55px;
}
}
</style>
