<template>
    <div class="career-info">
        <h3>{{userPosition}}</h3>
        <div class="progress-bar-container">
            <div class="progress-bar"></div>
        </div>
       
    </div>
</template>

<script>
    export default {
        name: "App",
        props: {
            userPosition: {
                type: String,
                required: true
            }
        },
        };
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
    h3{
        font-family: "League Spartan", serif;
        font-size: x-large;
        font-weight:400 ;
        color: #EA7B08;
        margin-bottom: 20px;
    }
    .career-info{
        height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .progress-bar{
        background-color: #E6E6E6;
        height: 15px;
        width: 98%;
        border-radius: 20px;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
    .progress-bar-container{
        height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }
    
</style>