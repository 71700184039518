<template>
      <div 
        class="user-photo" 
        :style="{ backgroundImage: `url(${backgroundImage})` }">
      </div>
  </template>
  
  <script>
  import DefaultImage from "../../../assets/icons/profile/cebolaDefault.svg"
  export default {
    props: {
      Image: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        defaultImage: DefaultImage, 
        backgroundImage: null
      };
    },
    mounted() {
      this.backgroundImage = this.Image ? this.Image : this.defaultImage;
    },
    watch: {
      Image(newImage) {
        this.backgroundImage = newImage ? newImage : this.defaultImage;
      }
    }
  }
  </script>
  
  <style scoped>
  .user-photo {
    border: 4px solid #EA7B08;
    height: 210px;
    width: 210px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  
  @media (min-width:900px){
    .user-photo{
      height: 350px;
      width: 350px;
    }
  }
  </style>
  