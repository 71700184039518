<template>
  <div class="card">
      <div class="card-content">
          <slot></slot> <!-- Slot agora está dentro de um contêiner controlado -->
      </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
};
</script>

<style scoped>
.card {
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: -5px 2px 15px 2px rgba(0, 0, 0, 0.2);
  padding-inline: 18px;
  width: 100%;
  min-width: 340px;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex-grow: 1; /* Faz com que o conteúdo ocupe todo o espaço disponível dentro do card */
  overflow: hidden; /* Evita que o conteúdo do slot ultrapasse os limites do card */
}

@media (min-width:900px){
    .card-content{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      height: 400px;
      gap: 30px;
    }
    .card{
      padding-inline: 50px;
    }
  }
</style>
