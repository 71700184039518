<template>
    <div class="self-progress-container">
        <img  class="top-left" src="../assets/Backgrounds/left-top.png">
        <img class="top-right" src="../assets/Backgrounds/right-top.png">
        <img class="bottom-left" src="../assets/Backgrounds/left-bottom.png">
        <img class="bottom-right" src="../assets/Backgrounds/right-bottom.png">
        <div class="content-card">
            <span>Descubra o Seu Autêntico Self</span>
            <div class="content-progress">
                <div class="progress-bar">
                    <div class="bar">
                        <div class="animated-bar" :style="barStyle"></div>
                    </div>
                    <div v-for="(userQuiz, index) in userQuizzes" :key="index" class="quiz">
                        <div class="quiz-item">
                            <div class="identifiers">
                                <div class="icon"><img :src="quizIcon(userQuiz.status)"></div>
                                <div :class="circleClass(userQuiz.status)" v-on:click="buttonClicked(userQuiz)"></div>
                            </div>
                            <div class="label" :style="labelColor(userQuiz.status)">{{userQuiz.quiz.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="trophy"><img :src="trophyIcon(allQuizAnswered)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            userQuizzes: [],
            idUser: '',
            token: localStorage.getItem('userJWT'),
        }
    },

    methods: {
        async fetchQuizzes() {
            const response = await axios.get(`http://localhost:443/user/${this.idUser}`, {
                headers: {
                    'Authorization': this.token,
                },
            })
             
            for (let i = 0; i < response.data.user_quizzes.length; i++) {
                this.userQuizzes.push(response.data.user_quizzes[i])
            }

            // this.userQuizzes.push({
            //     createdAt: "2025-01-13T14:09:42.239Z",
            //     idUser: this.idUser,
            //     quiz: {
            //         id: '',
            //         questions: [],
            //         description: 'example',
            //         name: 'Descubra suas Habilidades'
            //     },
            //     status: "finished",
            //     updatedAt: "2025-01-13T14:12:02.478Z",
            // });

            // this.userQuizzes.push({
            //     createdAt: "2025-01-13T14:09:42.239Z",
            //     idUser: this.idUser,
            //     quiz: {
            //         id: '',
            //         questions: [],
            //         description: 'example',
            //         name: 'Descubra suas Habilidades'
            //     },
            //     status: "finished",
            //     updatedAt: "2025-01-13T14:12:02.478Z",
            // });
            
            // this.userQuizzes.push({
            //     createdAt: "2025-01-13T14:09:42.239Z",
            //     idUser: this.idUser,
            //     quiz: {
            //         id: '',
            //         questions: [],
            //         description: 'example',
            //         name: 'Matching Profissional'
            //     },
            //     status: "finished",
            //     updatedAt: "2025-01-13T14:12:02.478Z",
            // });

            // this.userQuizzes.push({
            //     createdAt: "2025-01-13T14:09:42.239Z",
            //     idUser: this.idUser,
            //     quiz: {
            //         id: '',
            //         questions: [],
            //         description: 'example',
            //         name: 'Escolha sua Jornada'
            //     },
            //     status: "finished",
            //     updatedAt: "2025-01-13T14:12:02.478Z",
            // });
        },

        buttonClicked(userQuiz) {
            if (userQuiz.status === 'available' || userQuiz.status === 'notFinished') {
                console.log(userQuiz);
            }
        },

        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        }
    },

    computed: {
        allQuizAnswered() {
            let value = 0;

            for (let i = 0; i < this.userQuizzes.length; i++) {
                if (this.userQuizzes[i].status == 'finished') {
                    value += 1;
                }
            }

            if (this.userQuizzes.length === value) {
                return true;
            } else {
                return false;
            }
        },

        finishedQuizzes() {
            let value = 0;

            for (let i = 0; i < this.userQuizzes.length; i++) {
                if (this.userQuizzes[i].status == 'finished') {
                    value += 1;
                }
            }

            return value
        },

        notFinishedQuizzes() {
            let value = 0;

            for (let i = 0; i < this.userQuizzes.length; i++) {
                if (this.userQuizzes[i].status == 'notFinished') {
                    value += 1;
                }
            }

            return value;
        },

        quizIcon() {
            return (status) => {
                if (status === 'finished') {
                    return require('../assets/icons/finished.png');
                } else if (status === 'notFinished' || status === 'available') {
                    return require('../assets/icons/available.png');
                } else {
                    return require('../assets/icons/locked.png');
                }
            };
        },

        circleClass() {
            return (status) => {
                if (status === 'blocked') {
                    return 'circle';
                } else if (status === 'finished') {
                    return 'circle finished';
                } else if (status === 'notFinished' || status === 'available') {
                    return 'circle active';
                }
            }
        },

        labelColor() {
            return (status) => {
                if (status === 'finished') {
                    return 'color: #298487;';
                } else if (status === 'notFinished' || status === 'available') {
                    return 'color: #ea7b08;';
                }
            }
        },

        trophyIcon() {
            return (allQuizAnswered) => {
                if (allQuizAnswered) {
                    return require('../assets/icons/active-trophy.png');
                } else {
                    return require('../assets/icons/disactive-trophy.png');
                }
            }
        },

        barStyle() {
            let style = {};
            
            if (this.allQuizAnswered && this.windowWidth > 1300) {
                style.width = "100%";
            } else if (this.allQuizAnswered && this.windowWidth < 1300) {
                style.height = "100%";
            } else if (this.windowWidth > 1300) {
                style.width = (100 / this.userQuizzes.length) * this.finishedQuizzes + (this.finishedQuizzes * 2) + "%"; // ((this.finishedQuizzes * 25) + (this.finishedQuizzes * 1.7)) * (this.userQuizzes.length / this.finishedQuizzes)
            } else {
                style.height = (this.finishedQuizzes * 23.5) + (this.finishedQuizzes * 1.7) + "%";
            }

            return style;
        }
    },

    async mounted(){
        document.body.style.background = "#298487";
        window.addEventListener("resize", this.updateWindowWidth);

        this.fetchQuizzes();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.updateWindowWidth);
    }
}
</script>

<style scoped>
* {
    font-family: 'League Spartan';
}

.self-progress-container {
    min-height: 100vh;
    max-width: 100vw;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}


.content-card {
    background-color: white;
    width: 85%;
    height: 45rem;
    border-radius: 50px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-card span {
    color: #EA7B08;
    font-weight: 500;
    font-size: 55px;
    margin-top: 6rem;
    margin-bottom: 8rem;
}

.content-progress {
    display: flex;
    align-items: center;
    width: 90%;
}

.progress-bar {
    display: flex;
    flex: 1;
    align-items: start;
    position: relative;
}

.bar {
    width: 94%;
    height: 10px;
    border-radius: 10px;
    position: absolute;
    top: 53%;
    right: 0;
    background-color: #bcbcbc;
    overflow: hidden;
    z-index: 1;
}

.animated-bar {
    background-color: #298487;
    width: 0%;
    height: 10px;
    border-radius: 10px;
    z-index: 2;
}

.quiz {
    z-index: 2;
    width: 100%;
    height: 12rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.identifiers {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

}

.icon {
    width: 4rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.icon img {
    max-width: 100%;
    max-height: 100%;
}

.circle {
    width: 30px;
    height: 30px;
    background-color: white;
    border: 4px solid #989898;
    border-radius: 15px;
}

.circle.finished {
    background-color: #298487;
    border: 4px solid #298487;
}

.circle.active {
    background-color: #ea7b08;
    border: 4px solid #ea7b08;
    animation: pulse 1.5s ease infinite;
    cursor: pointer;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.label {
    margin-top: 20px;
    font-size: 23px;
    width: 10rem;
    text-align: center;
    color: #5e5e5e;
    cursor: default;
}

@media (max-width: 1690px) {
    .bar {
        width: 92%;
    }
}

@media (max-width: 1360px) {
    .bar {
        width: 89%;
    }
}

@media (max-width: 1300px) {
    .top-left, .top-right, .bottom-left, .bottom-right {
        width: 10rem;
        height: 10rem;
    }

    .top-left, .top-right, .bottom-left, .bottom-right img {
        max-width: 100%;
        max-height: 100%;
    }

    .content-card {
        height: auto;
        margin-block: 5rem;
        padding-bottom: 5rem;
    }

    .content-card span {
        padding-inline: 5rem;
        text-align: center;
        font-size: 48px;
    }

    .content-progress {
        flex-direction: column;
        align-items: start;
        width: 85%;
    }

    .progress-bar {
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }

    .bar {
        width: 10px;
        height: 100%;
        top: 0;
        left: 20%;
    }

    .animated-bar {
        width: 10px;
        height: 0%;
    }

    .quiz-item {
        width: 64%;
        flex-direction: column;
    }

    .identifiers {
        height: 20px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    .label {
        margin-left: 150px;
        text-align: left;
    }
}
</style>