import { createRouter, createWebHistory } from 'vue-router';
import RegisterPage from '@/views/RegisterPage.vue';
import UserVerification from '@/views/UserVerification.vue';
import ErrorSystemFailure from '@/views/ErrorSystemFailure.vue';
import SuccessfulRegistration from '@/views/SuccessfulRegistration.vue';
import FinalScreen from '@/views/FinalScreen.vue';
import UserProfile from '@/views/UserProfile.vue';
import LoginPage from '@/views/LoginPage.vue';
import QuizPage from '@/views/QuizPage.vue';
import StartMotivators from '@/views/StartMotivators.vue';
import WaitListPage from "@/views/WaitListPage.vue";
import UpdatePhotoPage from '@/views/UpdatePhotoPage.vue';
import AuthenticSelf from '@/views/AuthenticSelf.vue';

const router = createRouter({
  history: createWebHistory(''),
  routes: [
    {
      path:'/',
      component: LoginPage
    },
    {
      path: '/register',
      component: RegisterPage
    },
    {
      path: '/user/verification',
      component: UserVerification
    },
    {
      path: '/error/system-failure',
      component: ErrorSystemFailure
    },
    {
      path: '/register/successfulverification',
      component: SuccessfulRegistration
    },
    {
      path: '/final',
      component: FinalScreen
    },
    {
      path: '/profile', // adicionr /:idUser para implementar logica de popular dados
      component: UserProfile
    },
    {
      path: '/quiz/:idUser/:idQuiz',
      component: QuizPage
    },
    {
      path: '/start-quiz/:idUser/:idQuiz',
      component: StartMotivators
    },
    {
      path:'/waitList',
      component: WaitListPage
    },
    {
      path: '/photo/:idUser',
      component: UpdatePhotoPage
    },
    {
      path: '/authentic-self',
      component: AuthenticSelf
    },
  ]
})

export default router