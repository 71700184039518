<template>
  <LoadingComponent v-if="isLoading" class="loading" />
  <div class="body">

    <div class="container">
      <div class="header">
        <div class="icon"><img src="../assets/icons/cebola-azul.png"></div>
        <div class="title"><span>Cadastro</span></div>
      </div>

      <div class="main">
        <div class="error-container">
          <p class="backend-error-msg" v-show="hasBackendError">{{ backendErrorMsg }}</p>
        </div>
        <div class="inputs">
          <FormInput :required="true" type="text" id="name" placeholder="Nome Completo"
            :validator="(name) => (validateInputValue(name, /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/) && validateFormattedString(name))"
            :error-message="errorMessageShowed(name)" :backend-error-message="backendErrorMessage.name"
            @update:v-model="(value) => (name = value)" @validity-change="(value) => (validFields.name = value)" />

          <FormInput :required="true" type="text" id="email" placeholder="E-mail"
            :validator="(email) => validateInputValue(email, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)"
            error-message="Formato de e-mail inválido." :backend-error-message="backendErrorMessage.email"
            @update:v-model="(value) => (email = value)" @validity-change="(value) => (validFields.email = value)" />

          <div class="horizontal-inputs">
            <FormInput :required="true" type="date" id="dateOfBirth" placeholder="Aniversário"
              :validator="(birthDate) => validatebirthDate(birthDate)"
              error-message="Você precisa ter pelo menos 18 anos." :backend-error-message="backendErrorMessage.date"
              @update:v-model="(value) => (birthDate = value)"
              @validity-change="(value) => (validFields.date = value)" />

            <FormSelect :required="true" id="gender" default-label="Gênero" :validator="isGenderValid"
              :options="genderOptions" @update:selected="(value) => (gender = value)"
              @validity-change="(value) => (validFields.gender = value)" />
          </div>

          <FormInput :required="true" type="password" id="password" :padding-right="2.2" placeholder="Senha"
            :validator="(password) => validateInputValue(password, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/)"
            error-message="A senha deve ter no mínimo 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais."
            :backend-error-message="backendErrorMessage.password" :validateOnInput="true" @update:v-model="(value) => {
              password = value;
              checkPassword();
              }" @validity-change="(value) => (validFields.password = value)" />

          <div class="password-validations">
            <div class="validation">
              <div class="validation-icon">
                <img v-if="validation.hasMinimumLength" class="check" src="../assets/icons/check-3278.svg">
                <img v-else class="dot" src="../assets/icons/dot.png">
              </div>
              <div :class="[validation?.hasMinimumLength ? 'message-valid' : 'message-invalid']">Mínimo de 8 caracteres
              </div>
            </div>

            <div class="validation">
              <div class="validation-icon">
                <img v-if="validation.hasNumber" class="check" src="../assets/icons/check-3278.svg">
                <img v-else class="dot" src="../assets/icons/dot.png">
              </div>
              <div :class="[validation?.hasNumber ? 'message-valid' : 'message-invalid']">Pelo menos um número
              </div>
            </div>

            <div class="validation">
              <div class="validation-icon">
                <img v-if="validation?.hasSpecialCharacter" class="check" src="../assets/icons/check-3278.svg">
                <img v-else class="dot" src="../assets/icons/dot.png">
              </div>
              <div :class="[validation?.hasSpecialCharacter ? 'message-valid' : 'message-invalid']">Pelo menos um
                caractere especial</div>
            </div>

            <div class="validation">
              <div class="validation-icon">
                <img v-if="validation?.hasLowercase && validation.hasUppercase" class="check"
                  src="../assets/icons/check-3278.svg">
                <img v-else class="dot" src="../assets/icons/dot.png">
              </div>
              <div
                :class="[validation?.hasLowercase && validation?.hasUppercase ? 'message-valid' : 'message-invalid']">
                Pelo menos uma letra maiúscula e uma
                minúscula</div>
            </div>
          </div>

        </div>
      </div>

      <div class="footer">
        <div class="actions">
          <GenericButton :isValid="isFormValid()" @click="handleSubmit" />
          <div class="login"><button @click="navigateToLogin">Login</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";
import axios from "axios";
import FormInput from "@/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import GenericButton from "@/components/GenericButton.vue";
import LoadingComponent from '@/components/LoadingComponent.vue';
import router from "@/router";

const name = ref("");
const email = ref("");
const birthDate = ref("");
const gender = ref("");
const password = ref("");
const validation = ref({
  hasMinimumLength: false,
  hasSpecialCharacter: false,
  hasUppercase: false,
  hasLowercase: false
});
const validFields = ref({});
const isLoading = ref(false);
const backendErrorMessage = ref({});

const genderOptions = [
  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "female",
    label: "Feminino",
  },
  {
    value: "nonbinary",
    label: "Não-binário",
  },
];

function checkPassword() {
  if (!password.value) {
    validation.value = {
      hasMinimumLength: false,
      hasSpecialCharacter: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false
    };
    return;
  }

  validation.value = {
    hasMinimumLength: password.value.length >= 8,
    hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password.value),
    hasUppercase: /[A-Z]/.test(password.value),
    hasLowercase: /[a-z]/.test(password.value),
    hasNumber: /\d/.test(password.value)
  };
}

function validateInputValue(value, regex) {
  if (!regex.test(value)) {
    return false;
  }
  return true;
}

function validateFormattedString(input) {
    const cleaned = input ? input.replace(/\s+/g, ' ').trim() : '';
  
    if (cleaned.length > 500) {
      return false;
    }
    
    return true
}

function errorMessageShowed(value) {
  if (value == '') {
    return 'Nome é obrigatório.'
  } else if (!validateFormattedString(value)) {
    return 'O nome deve conter no máximo 500 caracteres.'
  } else {
    return 'Nome inválido. O nome não pode conter números ou caracteres especiais.'
  }
}

function validatebirthDate(birthDate) {
  const today = new Date();
  const birthDateValue = new Date(birthDate);
  let age = today.getFullYear() - birthDateValue.getFullYear();
  const m = today.getMonth() - birthDateValue.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDateValue.getDate())) {
    age--;
  }
  if (age < 18) {
    return false;
  }
  return true;
}

function isFormValid() {
  return (
    Object.values(validFields.value).every((isValid) => isValid) &&
    name.value != "" &&
    email.value != "" &&
    birthDate.value != "" &&
    gender.value != "" &&
    password.value != ""
  );
}

function isGenderValid(gender) {
  const validGeneros = ["male", "female", "nonbinary"];
  return validGeneros.includes(gender);
}

async function sendAuthenticationEmail(idUser) {
  try {
    await axios.post(`${process.env.VUE_APP_BASE_URL}/auth`, {
        idUser: idUser,
    });

    router.push('/user/verification');

  } catch (error) {
      router.push("/error/system-failure");
  } finally {
      isLoading.value = false;
  }
}

function importPublicKey() {
  const pem = `-----BEGIN PUBLIC KEY-----MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEkjBq21kHy+x5VzZBrL78U/VUlmWFaj7UKKpfFP+tgYCElpe8922lif77Zwv3Munjn6QmJSlwQGRLofN7m01ALA==-----END PUBLIC KEY-----`

  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";

  const pemContents = pem
    .replace(pemHeader, '')
    .replace(pemFooter, '')
    .trim()
    .replace(/\n/g, '');

  const binaryDer = base64ToArrayBuffer(pemContents);

  return crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "ECDH",
      namedCurve: "P-256"
    },
    true,
    []
  );
}

function navigateToLogin() {
  router.push('/');
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

async function handleSubmit() {
  isLoading.value = true;
  try {
    const publicKey = await importPublicKey();

    const clientKeyPair = await crypto.subtle.generateKey(
      {
        name: "ECDH",
        namedCurve: "P-256"
      },
      true,
      ["deriveKey", "deriveBits"]
    );

    const clientPublicKeyArrayBuffer = await crypto.subtle.exportKey(
      "raw", 
      clientKeyPair.publicKey
    );

    const clientPublicKeyBase64 = arrayBufferToBase64(clientPublicKeyArrayBuffer);

    const sharedSecret = await crypto.subtle.deriveKey(
      {
        name: "ECDH",
        public: publicKey
      },
      clientKeyPair.privateKey,
      {
        name: "AES-GCM",
        length: 256
      },
      true,
      ["encrypt", "decrypt"]
    );

    const encoder = new TextEncoder();
    const encodedPassword = encoder.encode(password.value);

    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedPassword = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      sharedSecret,
      encodedPassword
    );

    const encryptedPasswordBase64 = arrayBufferToBase64(encryptedPassword);
    const ivBase64 = arrayBufferToBase64(iv);

    const cleanedName = name.value.replace(/\s+/g, ' ').trim();
    const formatedName = cleanedName
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');


    const formData = {
      name: formatedName,
      email: email.value,
      date: `${birthDate.value}T00:00:00.000+00:00`,
      gender: gender.value,
      password: encryptedPasswordBase64,
      clientPublicKey: clientPublicKeyBase64,
      iv: ivBase64,
    };

    const response = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/user`,
      formData
    );
    
    localStorage.setItem('idUser', response.data.idUser)

    const id = response.data.idUser;
    
    sendAuthenticationEmail(id);
  } catch (error) {
    setTimeout(() => {
      isLoading.value = false;
    }, 2000);
    if (error.response.status === 401) {
        router.push(`/waitList`);
    }
    else if (error.response.status === 403) {
        validFields.value.email = false;
        backendErrorMessage.value.email = "";
        nextTick(() => {
          backendErrorMessage.value.email = "E-mail já registrado.";
        });
    }
    else if (error.response && error.response.data && error.response.data.status === 400) {
      setTimeout(() => {
        const input = error.response.data.input;
        validFields.value[input] = false;
        backendErrorMessage.value[input] = "";
        nextTick(() => {
          backendErrorMessage.value[input] = error.response.data.message;
        });
      }, 2000);
    } else {
      router.push("/error/system-failure");
    }
  }
}
</script>

<style scoped>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
.body {
  font-family: 'League Spartan', sans-serif;
}

.body {
  background-color: #ea7b08;
  background-image: url('../assets/half-white-blue-lines-bg.svg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #f9f9f9;
  width: 35rem;
  height: 45rem;
  border-radius: 30px;
  padding-inline: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 10px 10px 4px #00000040;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 65px;
  height: 70px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.icon img {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 50px;
  font-weight: 500;
  color: #ea7b08;
}

.main {
  display: flex;
  flex-direction: column;
  height: 24rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  height: 24rem;
}

.password-validations {
  height: 4rem;
  margin-block: 17px;
}

.validation {
  width: 100%;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  gap: 10px;
}

.validation .validation-icon {
  height: 100%;
  max-width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-valid {
  height: 100%;
  display: flex;
  align-items: end;
  color: #0ba900;
}

.message-invalid {
  height: 100%;
  display: flex;
  align-items: end;
  opacity: 50%;
}

.check {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  max-width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 50%;
}

.horizontal-inputs {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

#dateOfBirth::placeholder {
  opacity: 1;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:20px ;
}

.button {
  width: 100%;
}

.button button {
  background-color: #298487;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 18px;
  color: #f9f9f9;
  font-size: 20px;
  cursor: pointer;
}

.login button {
  background-color: transparent;
  border: none;
  color: #ea7b08;
  font-size: 16px;
  margin-block: 2rem;
  cursor: pointer;
}

.error-container {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}

.backend-error-msg {
  font-size: 20px;
  color: #ea7b08;
}

#gender {
  z-index: 999;
}

@media screen and (max-width:800px) {
  .body {
    background-image: none;
    align-items: start;
    max-height: 100dvh;
    max-width: 100dvw;
  }

  .container {
    width: 100%;
    min-height: 100dvh;
    border-radius: 0;
  }
}

@media screen and (max-width:560px) {
  .body {
    background-image: none;
    align-items: center;
    max-height: 100dvh;
    max-width: 100dvw;
  }

  .container {
    width: 100%;
    height: 100dvh;
    border-radius: 0;
  }
}

@media screen and (min-width: 801px) and (max-height: 724px) {
  .body {
    height: auto;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;
  }

  .container {
    height: auto;
    gap: 5em;
  }
}
</style>