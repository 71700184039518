<template>
    <header>
        <img id="engine" class="icon" src="../../assets/icons/profile/engine.svg" alt="engine photo">
        <img class="icon" src="../../assets/icons/profile/cebolaazul.svg" alt="onion photo">
    </header>
</template>

<script>

</script>

<style>
    header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100px;
    }
    #engine{
        visibility: hidden;
    }

    .icon{
        height: 45px;
        width: 45px;
    }
</style>