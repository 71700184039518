<template>
    <div class="overview">
        <DefaultCard>
            <div class="photo-container">
                <UserPhoto :Image="userImage"/>
            </div>
            <div class="user-info">
                <div class="main-info-container">
                    <MainInfo :userName="userName" :softSkills="softSkills" />
                    <div class="insignia-container">
                        <img class="insignia" :src="motivatorImage" alt="motivador">
                    </div>
                </div>
                <CareerInfo userPosition="Desenvolvedor de Software"/>
            </div>
        </DefaultCard>
    </div>
</template>
  
<script>
import DefaultCard from '@/components/Profile/DefaultCard.vue';
import UserPhoto from './OverviewComponents/UserPhoto.vue';
import MainInfo from './OverviewComponents/MainInfo.vue';
import CareerInfo from './OverviewComponents/CareerInfo.vue';

export default {
    name: "App",
    components: {
        DefaultCard,
        UserPhoto,
        MainInfo,
        CareerInfo
    },
    props: {
        motivatorImage: {
            type: String,
            required: true
        },
        userName: {
            type: String,
            required: true
        },
        softSkills: {
            type: String,
            required: true
        },
        userImage: { 
            type: String,
            required: false,
            default: ""
        }
    }
};
</script>

<style>
.overview {
    height: 600px;
    width: 100%;
}
.photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.insignia{
    height: 35px;
}

.main-info-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 170px;
}

.user-info{
    width: 100%;
}

</style>
