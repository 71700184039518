<template>
    <LoadingComponent v-if="loading" class="loading" />
    <div class="quiz-container">
        <img class="bg-img ellipse-top-right" src="../assets/Backgrounds/ellipse-top-right.svg" alt="elipse">
        <img class="bg-img line-top-right" src="../assets/Backgrounds/line-top-right.svg" alt="linha">

        <img class="bg-img ellipse-top-left" src="../assets/Backgrounds/ellipse-top-left.svg" alt="elipse">
        <img class="bg-img line-top-left" src="../assets/Backgrounds/line-top-left.svg" alt="elipse">

        <img class="bg-img ellipse-bottom-right" src="../assets/Backgrounds/ellipse-bottom-right.svg" alt="elipse">
        <img class="bg-img line-bottom-right" src="../assets/Backgrounds/line-bottom-right.svg" alt="linha">

        <img class="bg-img ellipse-bottom-left" src="../assets/Backgrounds/ellipse-bottom-left.svg" alt="elipse">
        <img class="bg-img line-bottom-left" src="../assets/Backgrounds/line-bottom-left.svg" alt="elipse">

        <div class="progress-bar-container">
            <ProgressBar v-show="!loading" :value="progressPercentage"/>
        </div>

        <div class="questions" v-show="!isLastPage">
            <ObjectiveQuestion v-for="question in paginatedQuestions"
                :key="question.id" 
                :statement="question.statement" 
                :alternatives="question.alternatives"
                :selected-alternative="selectedAlternative(question.id, question.alternatives)"
                @select-alternative="(alternative) => selectAlternative(question.id, alternative)"
            />
        </div>

        <div class="statements" v-show="isLastPage && !loading">
            <StatementList 
                title="Escolha as 3 afirmações que você mais se identificou." 
                :statements="greaterAffinityStatements" 
                :max-required="3" 
                @select-responses="(resps) => {selectedStatements = resps}"
            />
        </div>

        <div class="actions" v-if="!loading && !isLastPage">
            <button @click="previousPage" :disabled="currentPage === 1"
                :style="currentPage !== 1 ? 'opacity: 1': 'opacity: 0'" id="prev-page" class="arrow-page">
                <img src="../assets/icons/left-arrow.svg" />
            </button>
            <p>{{ currentPage }}/{{ totalPages - 1 }}</p>
            <div>
                <div v-if="isLastPage && (this.currentPage === this.totalPages - 1 && greaterAffinityStatements.length === 0)"
                    class="generic-button">
                    <GenericButton @click="redirectToFinal" text="Finalizar"/>
                </div>

                <button v-else @click="nextPage" :disabled="!allAnswered"
                    :style="isLastPage ? 'opacity: 0' : 'opacity: 1'" id="next-page" class="arrow-page">
                    <img src="../assets/icons/right-arrow.svg" />
                </button>
            </div>
        </div>

        <div class="actions" v-else-if="!loading">
            <p>{{ totalSelectedValue }}/3</p>

            <div class="generic-button">
                <GenericButton
                    @click="redirectToFinal" 
                    text="Finalizar" 
                    :is-valid="totalSelectedValue === 3"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GenericButton from '@/components/GenericButton.vue'
import ObjectiveQuestion from '@/components/Quiz/ObjectiveQuestion.vue';
import ProgressBar from '@/components/Quiz/ProgessBar.vue';
import StatementList from '@/components/Quiz/StatementList.vue';


export default {
  data() {
    return {
        token: '',
        idQuiz: '',
        idUser: '',
        questions: [],
        responses: {},
        selectedStatements: [],
        responsesPerPage: 0,
        currentPage: 1,
        questionsPerPage: 3,
        loading: true,
        allAnswered: false,
        responsesChanged: false,
    };
  },

  components: {
    LoadingComponent,
    GenericButton,
    ObjectiveQuestion,
    ProgressBar,
    StatementList,
  },

  computed: {
    paginatedQuestions() {
      const start = (this.currentPage - 1) * this.questionsPerPage;
      const end = start + this.questionsPerPage;
      return this.questions.slice(start, end);
    },

    totalPages() {
        return Math.ceil(this.questions.length / this.questionsPerPage) + 1;
    },

    isLastPage() {
        return this.currentPage === this.totalPages
    },

    greaterAffinityStatements() {
        let responses = Object.keys(this.responses)
        .filter(id => this.responses[id] >= 4)
        .map(id => ({ id: id, value: this.responses[id]}))

        if (responses.length < 3) {
            const additionalResponses = Object.keys(this.responses)
            .filter((id) => this.responses[id] === 3)
            .map((id) => ({ id: id, value: this.responses[id] }));

            responses = responses.concat(additionalResponses);
        }

        return responses.map(item => {
            const statementObj = this.questions.find(statement => statement.id === item.id);

            return {
                id: item.id,
                value: item.value,
                statement: statementObj.statement,
                theme: statementObj.theme
            };
        });
    },

    totalSelectedValue() {
        return this.selectedStatements.reduce((sum, statement) => sum + (statement.value || 0), 0);
    },

    statementsResponse() {
      return this.selectedStatements.map((response) => ({
        ...response,
      }));
    },

    progressPercentage() {
        return (this.currentPage / this.totalPages) * 100
    },
  },

  methods: {
    async fetchQuiz() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/quiz/${this.idUser}/${this.idQuiz}`, {
            headers: {
                'Authorization': this.token,
            }
        });
        this.questions = response.data.questions;
        this.loading = false;
        await this.loadStoredResponses();
      } catch (error) {
        if(error.response.data?.status === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },

    async loadStoredResponses() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/responses/${this.idUser}/${this.idQuiz}`, {
            headers: {
                'Authorization': this.token,
            }
        });
        const responses = response.data.responses || [];
        responses.forEach(resp => this.responses[resp.questionId] = resp.value) || {}
        this.applyStoredResponsesToCurrentPage();
      } catch (error) {
        if(error.data.status === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },

    applyStoredResponsesToCurrentPage() {
      this.paginatedQuestions.forEach((question) => {
        if (this.responses[question.id]) {
          question.selectedAlternative = this.responses[question.id];
        }
        this.allAnswered = this.allQuestionsAnswered()
      });
    },

    selectAlternative(questionId, alternativeValue) {
        this.responses[questionId] = alternativeValue;

        this.allAnswered = this.allQuestionsAnswered()
        this.responsesChanged = true;

        this.$forceUpdate();
    },

    selectedAlternative(questionId, alternatives) {
        const response = this.responses[questionId]
        return alternatives?.find(alternative => alternative.value === response)?.id || '';
    },

    allQuestionsAnswered() {
        return this.paginatedQuestions.every(question => this.responses[question.id] !== undefined);
    }, 

    async savePaginatedQuestionsResponses() {
        const questionsToSave = this.paginatedQuestions.map((question) => ({
        idQuestion: question.id,
        value: this.responses[question.id],
      }));

      await this.saveResponses(questionsToSave)
    },

    async saveStatementResponses() {
        await this.saveResponses(this.statementsResponse)
    },

    async saveResponses(responses) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/quiz/${this.idUser}/${this.idQuiz}`, {
          responses: responses,
        }, {
            headers: {
                'Authorization': this.token,
            }
        });
      } catch (error) {
        if(error.response.data?.status === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },

    async calculateResults() {
        try {
            await axios.get(`${process.env.VUE_APP_BASE_URL}/quiz/calculate-result/${this.idUser}/${this.idQuiz}`, {
                headers: {
                    'Authorization': this.token,
                }
            });
        } catch (error) {
            if(error.response.data?.status === 400) {
                router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`);
            }
            else if(error.response.data?.status  === 403) {
                router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`);
            }
            else if (error.response.data?.status === 401) {
                router.push({path: "/", query: {"expired": true}});
            }
            else {
                router.push("/error/system-failure")
            }
        }
    },

    async redirectToFinal() {
        this.loading = true
        try {
            if (!this.isLastPage) {
                await this.savePaginatedQuestionsResponses();
            }

            if (this.statementsResponse.length > 0) {
                await this.saveStatementResponses();
            }

            await this.calculateResults();

            router.push('/final');
        } catch (error) {
            alert('Ocorreu um erro ao finalizar. Por favor, tente novamente.');
        }
        finally {
            this.loading = false
        }
    },

    async nextPage() {
        if (this.allQuestionsAnswered()) {
            if (!this.isLastPage) {
                window.scrollTo({ top: 0});
                if (this.responsesChanged) {
                    this.loading = true;

                    await this.savePaginatedQuestionsResponses();
                    
                    this.responsesChanged = false;
                    
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }
                this.allAnswered = false;
                this.currentPage++;
                this.applyStoredResponsesToCurrentPage();
            }
        } else {
            alert('Por favor, responda todas as perguntas antes de avançar.');
        }
    },

    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.applyStoredResponsesToCurrentPage();
      }
    },

    toggleColor(statementId) {
      if (this.selectedStatements.includes(statementId)) {
        this.selectedStatements = this.selectedStatements.filter(id => id !== statementId);
      } else if (this.selectedStatements.length < 3) {
        this.selectedStatements.push(statementId);
      }
    },

    isMobile() {
      if (window.innerWidth <= 768) {
        this.questionsPerPage = 1;
      }
    }
  },

  async mounted() {
    this.isMobile();
    window.addEventListener('resize', this.checkIfMobile);
    this.token = localStorage.getItem('userJWT');

    this.idUser = this.$route.params.idUser;
    this.idQuiz = this.$route.params.idQuiz;
    await this.fetchQuiz();
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

.quiz-container {
    font-family: 'League Spartan';
    color: #ea7b08;
    width: 100vw;
    min-height: 100vh;
    min-width: 341px;
    padding-top: 3rem;
    padding-bottom: 5rem;
    padding-inline: 2.5rem;
    gap: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.ellipse-top-right, .line-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.ellipse-top-right {
    width: 128px;
}

.line-top-right {
    height: 64px;
}

.ellipse-top-left, .line-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.ellipse-top-left {
    width: 48px;
}

.line-top-left {
    height: 64px;
}

.ellipse-bottom-right, .line-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.ellipse-bottom-right {
    height: 128px;
}

.line-bottom-right {
    height: 96px;
}

.ellipse-bottom-left, .line-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.ellipse-bottom-left {
    width: 128px;
}

.line-bottom-left {
    width: 64px;
}

.progress-bar-container {
    width: 90%;
    margin-top: 3em;
    margin-bottom: 3em;
}

.questions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3em;
    min-height: 30em;
}

.statements {
    width: 100%;
}

.actions {
    width: 90%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.actions p {
    font-size: 1.75em;
}

.arrow-page {
    height: 3rem;
    background-color: transparent;
    border: none;
    color: black;
    display: flex;
    align-items: center;
}

.generic-button {
    width: 40%;
}

button {
    cursor: pointer;
    transition: all .2s;
}

button:hover {
    transform: scale(1.1);
    transition: all .2s;
}

button:disabled {
    cursor: default;
    transform: none;
    opacity: .6;
}

@media screen and (min-width: 810px) {
    .questions, .statements, .actions, .progress-bar-container {
        width: 70%;
    }

    .questions {
        gap: 7em;
    }

    .ellipse-top-right {
        width: 256px;
    }

    .line-top-right {
        height: 128px;
    }

    .ellipse-top-left {
        width: 96px;
    }

    .line-top-left {
        height: 128px;
    }

    .ellipse-bottom-right {
        height: 256px;
    }

    .line-bottom-right {
        height: 192px;
    }

    .ellipse-bottom-left {
        width: 256px;
    }

    .line-bottom-left {
        width: 128px;
    }
}

@media screen and (min-width: 1080px) {
    .progress-bar-container {
        width: 60%;
    }

    .generic-button {
        width: 30%;
    }
}
</style>